import { template as template_3177f4de8be74286833e5708de204051 } from "@ember/template-compiler";
const WelcomeHeader = template_3177f4de8be74286833e5708de204051(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
