import { template as template_51733f379cbb48d5bcd5e8817b6b29ea } from "@ember/template-compiler";
const FKText = template_51733f379cbb48d5bcd5e8817b6b29ea(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
