import { template as template_897037f6f1474abaac82da3bd0268b44 } from "@ember/template-compiler";
const FKLabel = template_897037f6f1474abaac82da3bd0268b44(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
